.App {
  text-align: center;
  background-color: #6FA0F9;
  height: 100vh;

  &__container {
    padding-top: 9.375rem;

    &.hidden {
      display: none;
    }
    
    &-title {
      margin: 0;
      color: #B94460;
      &.hidden {
        display: none;
      }
    }

    &-text {
      color: #B94460;
      &.hidden {
        display: none;
      }
    }
    
    &-please {
      display: none;

      &.hidden {
        display: block;
        color: #B94460;
        padding-bottom: 1.25rem;
      }
    }

    &__question {
      padding-top: 1.5625rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      &-yes {
        border: none;
        font-size: 1.5rem;
        width: 6.25rem;
        border-radius: .625rem;
        background-color: #F57A93;
        color: #B94460;
      }

      &-no {
        border: none;
        font-size: 1.5rem;
        width: 6.25rem;
        border-radius: .625rem;
        background-color: #F57A93;
        color: #B94460;
        position: relative;
        transition: transform 0.5s ease;
      }
    }
  }

  &__yes-container {
    display: none;

    &.hidden {
      display: block;
      padding-top: 9.375rem;
    }

    &__title {
      margin: 0;
      color: #B94460;
      padding-bottom: 1.875rem;
      font-size: 3rem;
    }

    &__kiss {
      height: 31.25rem;
    }
  }

}
